// Import the edliy_utils
import {
    createSpace,
    placeLogo,
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeSliderSwitch,
    placeRedo,
    placeUndo,
    writeHTMLText,
    drawPoint,
    drawLine,
    drawArrow,
    drawAngle,
    drawDash
} from '../Utils';
const Boxes = {
box1: function () {
JXG.Options.point.showInfoBox=false;
JXG.Options.point.highlight=false;
JXG.Options.text.highlight=false;
JXG.Options.image.highlight=false;
JXG.Options.text.fixed=true;
JXG.Options.curve.highlight=false;
JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
//
var graph=createSpace(-7, 5, -2, 10);
graph.options.layer['image']=10;
graph.options.layer['arrow']=9;
//Title and tetxs
placeTitle(graph, 'Driving on Banked Curves', '(Finding critical speed)');
makeResponsive(graph);
placeLogo(graph);
//Creating axes
//createAxes(graph);
//Inserting background image
graph.create('image',['/assets/floor.svg', [-7, -2], [20, 2.3]], {fixed: true});
//
var m=0;
var next = placeRedo(graph);
next.setAttribute({visible:()=>m<4});
//
var prev =placeUndo(graph, 'left');
prev.setAttribute({visible:()=>m>0});
//
next.on('down', function(){
  if(m<4){m+=1}else{m=4}
  if(m==0){
    im.setAttribute({fillOpacity:0.5});
  }
  if(m==1){
    im.setAttribute({fillOpacity:0.5});
  }
  if(m==2){
    im.setAttribute({fillOpacity:0.5});
    origL.moveTo([0.5, 3.5], 200);
  }
  if(m==3){
    im.setAttribute({fillOpacity:0.5});
    origT.moveTo([2, 5.], 200);
  }
});
//
prev.on('down', function(){
  if(m>0){m-=1}else{m=0}
  if(m==0){
    im.setAttribute({fillOpacity:0.5});
  }
  if(m==1){
    im.setAttribute({fillOpacity:0.5});
  }
  if(m==2){
    im.setAttribute({fillOpacity:0.5});
  }});
// Texts
//Problem Statement
writeHTMLText(graph, -6, 7.5, 'Imagine driving a car on a banked road with radius r and angle &theta;.').setAttribute({visible:()=>m==0, anchorX:'left'});
writeHTMLText(graph, -6, 6.5, 'Goal is to find the critical speed of the car.').setAttribute({visible:()=>m==0, anchorX:'left'});
writeHTMLText(graph, -6, 5.5, 'Assume no friction between the car and the road.').setAttribute({visible:()=>m==0, anchorX:'left'});
//
writeHTMLText(graph, -6, 7.5, 'Start with free body diagram of the car.').setAttribute({visible:()=>m==1, anchorX:'left'});
writeHTMLText(graph, -6, 6.5, 'A free body diagram shows all the external forces acting on the body.').setAttribute({visible:()=>m==1, anchorX:'left'});
//
writeHTMLText(graph, -6, 7.5, 'Balance of forces in x-direction gives:').setAttribute({visible:()=>m>1, anchorX:'left'});
writeHTMLText(graph, -6, 6.5, 'N sin&theta; =  mv^2/r').setAttribute({visible:()=>m>1, anchorX:'left'});
//
writeHTMLText(graph, -6, 5.5, 'Balance of forces in y-direction gives:').setAttribute({visible:()=>m>2, anchorX:'left'});
writeHTMLText(graph, -6, 4.5, 'N cos&theta; = mg').setAttribute({visible:()=>m>2, anchorX:'left'});
//
writeHTMLText(graph, -6, 3.5, 'Solving for v gives:').setAttribute({visible:()=>m>3, anchorX:'left'});
writeHTMLText(graph, -6, 2.5, 'v = (rg tan &theta;)^1^/^2').setAttribute({visible:()=>m>3, anchorX:'left'});
//
var mg = writeHTMLText(graph, 2., 1.3, 'mg').setAttribute({visible:()=>m>0, anchorX:'middle'});
//////////////////////
var cfg = writeHTMLText(graph, 4., 3.5, 'mv^2/r').setAttribute({visible:()=>m>0, anchorX:'left'});
////////////////////////////
var norm = writeHTMLText(graph, -0., 5., 'N').setAttribute({visible:()=>m>0, anchorX:'left'});
/////////////////////////////
var normL = writeHTMLText(graph, 0.3, 3.5, 'N sin&theta;').setAttribute({visible:()=>m>1, anchorX:'right'});
////////////
var norm = writeHTMLText(graph, -0., 5., 'N').setAttribute({visible:()=>m>0, anchorX:'left'});
/////////////
var normT = writeHTMLText(graph, 2., 5.5, 'N cos&theta;').setAttribute({visible:()=>m>2, anchorX:'middle'});
///
writeHTMLText(graph, 0.5, 3.75, 'r').setAttribute({visible:()=>m==0, anchorX:'middle'});
//////////////////
var Pt1 = drawPoint(graph, 0, 0.3);
Pt1.setAttribute({visible:false});
/////
var Pt2 = drawPoint(graph, 2, 2.3);
Pt2.setAttribute({visible:false});
/////////
var im = graph.create('image',['/assets/car.svg', [2, 2], [2, 2]], {rotate:45, opacity:0.65, fixed: true});
//////////////////////
var curb =drawLine(graph, Pt1, Pt2);
curb.setAttribute({strokeWidth:4});
//
//Origin
var orig = drawPoint(graph, 2., 3.5);
orig.setAttribute({visible:false});
var down = drawPoint(graph, 2., 1.5);
down.setAttribute({visible:false});
var left = drawPoint(graph, 4, 3.5);
left.setAttribute({visible:false});
var norm = drawPoint(graph, 0.5, 5.0);
norm.setAttribute({visible:false});
//
var origL = drawPoint(graph, 0.5, 5);
origL.setAttribute({visible:false});
var origT = drawPoint(graph, 0.5, 5);
origT.setAttribute({visible:false});
//origL.setAttribute({visible:false});
////
drawArrow(graph, orig, down).setAttribute({visible:()=>m>0, strokeColor:'red', dash:0});
drawArrow(graph, orig, left).setAttribute({visible:()=>m>0, strokeColor:'blue', dash:0});
drawArrow(graph, orig, norm).setAttribute({visible:()=>m>0, strokeColor:'green', dash:0});
drawArrow(graph, orig, origL).setAttribute({visible:()=>m>1, strokeColor:'green', dash:2});
drawArrow(graph, orig, origT).setAttribute({visible:()=>m>2, strokeColor:'green', dash:2});
/////////////////
var og = drawPoint(graph, 0, 0.3);
og.setAttribute({visible:false});
var start = drawPoint(graph, 2, 0.3);
start.setAttribute({visible:false});
var end = drawPoint(graph, 2, 2.3);
end.setAttribute({visible:false});
//////////////
drawAngle(graph, end ,og, start).setAttribute({name:'&theta;',strokeColor:'black', radius:1, center:{visible:false}, radiusPoint:{visible:false}, anglePoint:{visible:false}});
//
var Pt1 = drawPoint(graph, -1, -3);
Pt1.setAttribute({visible:false});
var Pt2 = drawPoint(graph, -1, 8);
Pt2.setAttribute({visible:false});
drawDash(graph, Pt1, Pt2).setAttribute({visible:()=>m==0, strokeColor:'grey',dash:1});
//
var Pt3 = drawPoint(graph, -1, 3.5);
Pt3.setAttribute({visible:false});
var Pt4 = drawPoint(graph, 2, 3.5);
Pt4.setAttribute({visible:false});
drawDash(graph, Pt3, Pt4).setAttribute({visible:()=>m==0, strokeColor:'grey'});
},
}
export default Boxes;
